@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  85% { transform: rotate(3deg); }
  95% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-image: url(assets/imgs/shrimp-bg.png);
  font-family: "Poppins", sans-serif;
}

.base-page {
  width: 1280px;
  margin: 0 auto;
}

.blue-box {
  background: linear-gradient(135.952deg, #61D6DF 0%, #499EA4 100%);
  border: 4px solid #003C64;
  border-bottom: 9px solid #003C64;
  border-radius: 15px;
  padding: 20px;
  user-select: none;
}

.white-box {
  background: white;
  border: 4px solid #003C64;
  border-radius: 15px;
  padding: 20px;
}

.orange-btn {
  border: 4px solid #003C64;
  border-radius: 15px;
  background: linear-gradient(180deg, #FB7B4C 0%, #EB6B57 100%);
  font-family: "Pacifico", cursive;
  font-size: 52px;
  color: white;
  animation: wiggle 2s infinite;
}

.orange-btn:hover {
  background: linear-gradient(180deg, #fb7b4ccc 0%, #EB6B57cc 100%);
}

.green-btn {
  border: 4px solid #003C64;
  border-radius: 15px;
  background: linear-gradient(180deg, #78e844 0%, #5ca13b 100%);
  font-size: 52px;
  font-family: "Pacifico", cursive;
  color: white;
}

.green-btn:hover {
  background: linear-gradient(180deg, #78e844cc 0%, #5ca13bcc 100%);
}

.green-btn-2 {
  border: none;
  background: linear-gradient(180deg, #78e844 0%, #5ca13b 100%);
  font-size: 26px;
  font-family: "Pacifico", cursive;
  color: white;
}

.green-btn-2:hover {
  background: linear-gradient(180deg, #78e844cc 0%, #5ca13bcc 100%);
}

.submit-btn-tg {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.red-btn {
  border: 4px solid #003C64;
  border-radius: 15px;
  background: linear-gradient(180deg, #fb4c4c 0%, #c04646 100%);
  font-size: 52px;
  font-family: "Pacifico", cursive;
  color: white;
}

.red-btn:hover {
  background: linear-gradient(180deg, #fb4c4ccc 0%, #c04646cc 100%);
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

#logo {
  transition: 0.5s;
}

#logo:hover {
  transform: rotate(360deg);
  transition: 0.5s;
}

.nav-btn:hover {
  transition: 0.3s;
}

.nav-btn:hover {
  opacity: 80%;
  transition: 0.3s;
}

.user-card {
  background: linear-gradient(135.952deg, #61D6DF 0%, #499EA4 100%);
  border: 4px solid #003C64;
  border-bottom: 9px solid #003C64;
  border-radius: 15px;
  padding: 50px;
  color: white;
}

.lost-message {
  color: white;
  font-family: "Pacifico", cursive;
  font-size: 52px;
  padding-bottom: 10px;
}

.retry-btn {
  background: linear-gradient(180deg, #c2c8d2 0%, #9a9fa7 100%);
  border: 4px solid #003C64;
  border-radius: 15px;
  padding: 10px;
  color: white;
}

.new-game-btn {
  background: linear-gradient(180deg, #78e844 0%, #5ca13b 100%);
  border: 4px solid #003C64;
  border-radius: 15px;
  padding: 10px;
  color: white;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  width: 550px;
  background: white;
  border: 4px solid #003C64;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mt-custom {
  margin-top: 96px !important;
}